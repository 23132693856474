import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';

import logo from '../media/favicon.svg';

const currentYear = new Date().getFullYear();

function Footer() {

  return (
    <div className='footer' id='footer'>
      <div className='footer__top'>
        <div className='footer__left'>
          <div className='footer-adress'>
            <FontAwesomeIcon icon={faHome} />
            <p>
              <a href='https://www.google.com/maps/place/Carl-Friedrich-Wilhelm-Borgward-Stra%C3%9Fe+10,+28832+Achim'>
                SK-Metallbau<br/>
                Carl-F.-W.Borgwardstraße 10<br/>
                28832 Achim
              </a>
            </p>
          </div>
          <div className='footer-phone'>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              <a href='tel:04202524775'>
                +49 4202 524775
              </a>
            </p>
          </div>
          <div className='footer-mail'>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>
              <a href="mailto:info@sk-metallbau.eu">
                info@sk-metallbau.eu
              </a>
            </p>
          </div>
        </div>
        <div className='footer__right'>
          <div className='footer__impressum'>
            <p>
              <a href='/impressum#impressum'>Impressum</a>
            </p>
            <p>
              | 
            </p>
            <p>
              <a href='/impressum#datenschutz'>Datenschutzerklärung</a>
            </p>
          </div>
          <AnchorLink href='#start'>
            <div className='top' id='top'>
              <FontAwesomeIcon icon={faLongArrowAltUp} size='1x' />
            </div>
          </AnchorLink>
        </div>
      </div>
      <div className="footer__bottom">
        <div className='footer__right'>
          <p className='copyright'>
            &copy; {currentYear} SK-Metallbau. Alle Rechte vorbehalten.
          </p>
          <img src={logo} alt='favicon' />
        </div>
      </div>
  </div>
  );
}

export default Footer;
