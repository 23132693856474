import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Nav from './components/Nav';
import Header from './components/Header';
import Subheader from './components/Subheader';
import References from './components/References';
import Skills from './components/Skills';
import Team from './components/Team';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Impressum from './components/Impressum';
import SmallHeader from './components/SmallHeader';
import BurgerMenu from './components/BurgerMenu';
import SkillSmall from './components/SkillsSmall';
import Leistungen from './components/Leistungen';

import galleryHeader from './media/jpg/nick-fewings-AjxMoYHZsDU-unsplash.jpg';
import impressumHeader from './media/jpg/rob-lambert-9Q_pLLP_jmA-unsplash.jpg';
import leistungHeader from './media/jpg/patrick-boucher-5FElocP1c40-unsplash.jpg';

import GlobalStyle from './GlobalStyles';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos : 0
    };
  }

  render() {
    return (
      <Router>
      <div className='app'>
        <GlobalStyle />
          <Switch>
            <Route path='/' exact>
              <Nav site={1}/>
              <BurgerMenu site={1}/>          
              <Header />
              <Subheader />
              <References />
              <Skills />
              <SkillSmall />
              <Team />
            </Route>
            <Route path='/referenzen' exact>
              <Nav site={2}/>
              <BurgerMenu site={2}/>      
              <SmallHeader header='Referenzen' image={galleryHeader}/>
              <Gallery />
            </Route>
            <Route path='/impressum' exact>
              <Nav site={2}/>
              <BurgerMenu site={2}/>      
              <SmallHeader header='Impressum' image={impressumHeader} />
              <Impressum />
            </Route>
            <Route path='/leistungen' exact>
              <Nav site={2}/>
              <BurgerMenu site={2}/>
              <SmallHeader header='Leistungen' image={leistungHeader} />
              <Leistungen />
            </Route>
          </Switch>
        <Footer />
      </div>
    </Router>
    );
  }
}

export default App;